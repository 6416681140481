import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import UnifacisaSubjectView from '@/views/UnifacisaSubjectView.vue'
import NotFound from '@/views/NotFound.vue'
import VideosView from '@/views/VideosView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/subjects/:subjectId',
    name: 'Subjects',
    component: UnifacisaSubjectView
  },
  {
    path: '/videos/:id',
    name: 'Videos',
    component: VideosView
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
