<template>
  <div>
    <div class="row mt-4">
      <h3>Vídeos</h3>
      <hr />
    </div>
    <div class="row">
      <div class="content">
        <div id="menu">
        </div>

        <div id="item">
          <h3 class="mt-2">
            Desenvolvendo uma API com Spring Boot, JPA, Lombok e MySql
          </h3>
          <iframe
            width="860"
            height="615"
            src="https://www.youtube.com/embed/PInQVesdiYg?si=d4PNlVyia_Ld6sVX"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
}
</style>
