<template>
    <footer class="my-md-3 pt-md-5 border-top">
        <div class="row">
            <div class="col-6 col-md">
                <small class="d-block mb-3 text-muted"> © 2022–2023</small>
            </div>
            <div class="col-6 col-md">
                <!--
          <h5>Links</h5>
          <ul class="list-unstyled text-small">
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="https://linktr.ee/genilsonmm">Link</a>
            </li>
          </ul>-->
            </div>
            <div class="col-6 col-md">
                <h5>Cursos</h5>
                <ul class="list-unstyled text-small">
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none"
                            href="https://www.udemy.com/course/spring-boot-pratico-jpa-restful-security-jwt-e-mais/?referralCode=3CDD4D1877D87BF5BF7C">
                            <b>Spring Boot Prático: JPA, RESTFul, Security, JWT e mais</b>
                        </a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" 
                        href="https://www.udemy.com/course/microservices-do-0-com-spring-cloud-netflix-eureka-e-mais/?referralCode=1DE0B883EF0048B2E3F0">
                            <b>Microservices do 0 com Spring Cloud, Netflix Eureka e mais</b>
                        </a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" 
                        href="https://www.udemy.com/course/nodejs-saindo-do-zero-com-varios-projetos/?referralCode=BF74246A452700578F40">
                            <b>NodeJS: Saindo do zero com vários projetos</b>
                        </a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" href="#">Vue3 [Em breve]</a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" href="#">Docker [Em breve]</a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" href="#">Java para iniciantes [Em breve]</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-md">
                <h5>Redes sociais</h5>
                <ul class="list-unstyled text-small">
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" href="https://www.instagram.com/gm2.dev.br/"
                            target="_blank"><i class="bi bi-instagram"> Instagram</i></a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" href="https://github.com/genilsonmm"
                            target="_blank"><i class="bi bi-github"> GitHub</i></a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none"
                            href="https://www.linkedin.com/in/genilsonmedeiros/" target="_blank"><i
                                class="bi bi-linkedin"> Linkedin</i></a>
                    </li>
                    <li class="mb-1">
                        <a class="link-secondary text-decoration-none" href="https://www.youtube.com/user/genilsonmm"
                            target="_blank"><i class="bi bi-youtube"></i> Canal no YouTube</a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>
<script>
    export default {

    }
</script>