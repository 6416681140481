<template>
  <div>
    <Header class="bg-light shadow"></Header>
    <div class="container">
      <router-view :key="$route.path" /> <!-- $route.path faz com que o router detecte que o carametro mudou e recarregue a página -->
      <Footer></Footer>
    </div>
  </div>
</template>

<script>

  import Header from '@/components/view-base/HeaderComponent'
  import Footer from '@/components/view-base/FooterComponent'

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: #2c3e50;
    margin-top: 10px;
  }

  .global-font-style {
    color: #383B7C;
    font-weight: 700;
  }

  .main-font-style:hover {
    color: #3889BD;
  }
</style>